body {
  background-color: #fafafa;
  margin: 0;
  font-family: Rubik, sans-serif;
}
header {
  background-color: #7c3aed;
  padding: 0.5rem 0;
  text-align: center;
  color: white;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
}
.legend {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem ;
}
.title {  
  font-size: 1rem;
}
.subtitle {
  font-size: 0.7rem;
}
main, form  {
  margin: 2rem auto 0 auto;
  max-width: 1000px;
  width: 90%;
  padding: 3rem;
  background-color: white;
  box-shadow: -1px 15px 62px -35px rgba(0,0,0,0.96);
  -webkit-box-shadow: -1px 15px 62px -35px rgba(0,0,0,0.96);
  -moz-box-shadow: -1px 15px 62px -35px rgba(0,0,0,0.96);
  border-radius: .8rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: center;
}

.captcha {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: center;
}

form label {
  margin: .5rem 0;
}


form label {
  display: block;
  margin: 1rem 0;
  color: #71717a;
  text-transform: uppercase;
}

form input[type="text"] {
  width: 50%;
  border-radius: .3rem;
  border: 1px solid #cbd5e1;
  padding: .5rem;
  float: right;
}
form input[type="submit"] {
  background-color: #1c2d41;
  border: none;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  padding: .5rem;
  display: block;
  width: 50%;
}
form input[type="submit"]:hover {
  cursor: pointer;
  background-color: #101a26;
}


.alerta {
  border-bottom: 2px solid #d51007;
  text-align: center;
  padding-bottom: .5rem;
  width: auto;
  display: block;
}

.text-center {
  text-align: center;
}
.letra {
  white-space: pre-wrap;
}

.item-fecha {
  color: #3E4095;
  font-weight: bold;
}

.dest-title{
  font-weight: bold;
  font-size: 0.9em;
}

.dest-detalle {
  color: #3E4095;
  font-size: 0.9em;
}

.no-encontrado {
  text-align: center;
  padding-top: 100px;
}

.btn-buscar {
  padding-top: 40px;
}

.btn-buscar input[type="submit"]{
  width: 300px;
}

.nro-tracking {
  font-weight: bolder;
}
/***** Inicio Loading *************/
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
/***** Fin Loading *************/

/***** Inicio Stepper *************/
.StepperContainer-0-2-1 {
  padding: 24px 0px !important;

}
/***** Fin Stepper *************/

@media (max-width: 768px) {
  main, form {
    padding: 3rem 1rem;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: center;
  }

  form input[type="text"] {
    width: 90%;
  }
  form input[type="submit"] {
    width: 90%;
  }
  
  .StepperContainer-0-2-1 {
    padding: 12px 0px !important;
  
  }
  
}
